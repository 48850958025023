





















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import AssignCard from "@/components/common/AssignCard.vue";
import MainSlogan from "@/components/common/MainSlogan.vue";
import WordOnPic from "@/components/common/WordOnPic.vue";
import AppArea from "@/components/common/AppArea.vue";
import Applyin from "@/components/common/Applyin.vue";
@Component({
  components: {
    Layout,
    AssignCard,
    MainSlogan,
    WordOnPic,
    AppArea,
    Applyin,
  },
})
export default class Charge extends Vue {
  bg = require("@/assets/charge/img/bg.png");
  bg2 = require("@/assets/charge/img/bg2.png");
  bg3 = require("@/assets/charge/img/bg3.png");
  bg4 = require("@/assets/charge/img/bg4.png");
  bg5 = require("@/assets/charge/img/bg5.png");
  bg6 = require("@/assets/charge/img/bg6.png");
  bg7 = require("@/assets/charge/img/bg7.png");
  appbg = require("@/assets/common/img/appbg.png");
  mbg = require("@/assets/charge/img/mobile1.png");
  arrawwhite: string = require("@/assets/home/img/icon_arrow_r.png");
  arrawblack: string = require("@/assets/home/img/arrawblack.png");
  cswiper = [
    {
      src: require("@/assets/charge/img/s1.png"),
    },
    {
      src: require("@/assets/charge/img/s2.png"),
    },
  ];
  mobileArea = [
    {
      id: 1,
      image: this.bg3,
      title: "充电，让生活更惬意",
      des: [
        "夜晚归来，电动汽车驶入车库连接充电桩开始充电，充满后自动断电，次日满电出行。",
      ],
    },
    {
      id: 2,
      image: this.bg4,
      title: "目的地充电",
      des: [
        "适用于酒店、商场、写字楼等目的地充电场景。",
        "当前往楼宇或其附近办事，停留时间长较久时，为电动汽车提供便利的充电服务。",
      ],
    },
    {
      id: 3,
      image: this.bg5,
      title: "不想旅途变囧途",
      des: [
        "誉照致力于打造完善的电站布局，为长途驾驶的电动汽车用户在景区、高速公路上等全场景布局充电站，无惧窘境，自在出行。",
      ],
    },
    {
      id: 4,
      image: this.bg6,
      title: "一键加电<br />道路救援 上门服务",
      des: [
        "当电动汽车用户急需充电但又无桩可充时，拨打誉照热线电话或者点击APP中的“一键加电”服务，服务专员将会在最短的时间内帮助您解决因电量不足而停滞的困境。",
        "当电动汽车用户即将前往另一地点却发现车辆需要充电时，拨打誉照客服热线电话或者点击APP中的“一键加电”服务，服务专员将会赶往现场为其充电，并在充电完成后将车辆送往指定地点。",
        "*仅在部分城市开放",
      ],
    },
  ];
  hmore() {
    this.$router.push({
      path: "/charge/family",
    });
  }

  tmore() {
    this.$router.push({
      path: "/charge/trip",
    });
  }
  mmore() {
    this.$router.push({
      path: "/charge/mobile",
    });
  }

  navigate(path: string) {
    this.$router.push({
      path,
    });
  }
}

import { render, staticRenderFns } from "./AppArea.vue?vue&type=template&id=0b9c5992&scoped=true&"
import script from "./AppArea.vue?vue&type=script&lang=ts&"
export * from "./AppArea.vue?vue&type=script&lang=ts&"
import style0 from "./AppArea.vue?vue&type=style&index=0&id=0b9c5992&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9c5992",
  null
  
)

export default component.exports